import "./userManager.scss";
import { Fragment, useEffect, useState } from "react";

import adminModel from "../../../models/adminModel";

import ColorField from "../../../common/ColorField/colorField";

import UserManagerTable from "../../../custom/UserManagerTable/userManagerTable";

import columnsList from "./userManager.json";

import { Grid, GridRow } from "../../../common/Grid/grid";
import TableSearch from "../../../common/TableSearch/tableSearch";

const UserManager = ({
  edit,
  setEdit,
  setEditPassword,
  setModalMessage,
  setModalDecision,
}) => {
  const [userList, setUserList] = useState([]); // Guarda la lista de usuarios
  const [search, setSearch] = useState(""); // Guarda el string de busqueda
  const [showUserList, setShowUserList] = useState([]); // Guarda la lista de usurios que aparecera el la tabla

  const getAllUser = adminModel.useGetAll(); // Hook que permite consultar la lista de usuarios
  const removeUser = adminModel.useRemove(); // Hook que permite consultar la lista de usuarios

  // Al modificar un usuario se vuelve a consultar la lista de usuarios
  useEffect(() => {
    if (edit === null) getAllUser.fetch();
  }, [edit]);

  // Recibo la respuesta de la consulta de la lista de usuarios
  useEffect(() => {
    if (getAllUser.res.status === "load")
      setUserList(getAllUser.res.data.sort((a, b) => a.id - b.id));
  }, [getAllUser.res.status]);

  // Recibo la respuesta de la consulta de eliminacion de usuario
  useEffect(() => {
    if (removeUser.res.status === "load") {
      getAllUser.fetch();
      setModalMessage({
        type: "success",
        message: "Usuario eliminado exitosamente",
      });
    }
    if (removeUser.res.status === "error") {
      setModalMessage({
        type: "error",
        message: "Error al eliminar el usuario",
      });
    }
  }, [removeUser.res.status]);

  // Agrego estilo a los campos de role y status de la tabla de usuarios
  useEffect(() => {
    const setComp = (item) => {
      const color =
        item.statusId.id === 1
          ? "green"
          : item.statusId.id === 2
          ? "red"
          : "yellow";
      const role = item.role.map((role) => (
        <ColorField
          key={role.id}
          id={role.id}
          label={role.label}
          color="grey"
        />
      ));
      const status = (
        <ColorField
          id={item.statusId.id}
          label={item.statusId.label}
          color={color}
        />
      );

      return { ...item, role: role, statusId: status };
    };
    const auxUserList = userList.map((item) => setComp(item));
    setShowUserList(auxUserList);
  }, [userList]);

  const deleteUser = (id) => {
    setModalDecision({
      type: "warning",
      message: "¿Está seguro que desea eliminar el usuario?",
      accept: () => removeUser.fetch(id),
    });
  };

  return (
    <div className="user-manager-content">
      <Fragment>
        <Grid>
          <GridRow width={8}></GridRow>
          <GridRow width={4}>
            <TableSearch
              id="search-farm"
              label=""
              size="medium"
              placeholder="Buscar"
              labelPosition="side"
              value={search}
              onSearch={setSearch}
            />
          </GridRow>
        </Grid>
        <UserManagerTable
          columnsList={columnsList}
          userList={showUserList.filter(
            (item) =>
              item.name.includes(search) || item.lastname.includes(search)
          )}
          setEdit={setEdit}
          setEditPassword={setEditPassword}
          deleteUser={deleteUser}
        />
      </Fragment>
    </div>
  );
};

export default UserManager;

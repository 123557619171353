import { useState, useEffect } from "react";
import axios from "axios";
import userAuth from "../hooks/userAuth";

const client = axios.create({
  baseURL: `${process.env.REACT_APP_URL_SERVER}/clients`,
  timeout: 31000,
  headers: {
    "Content-Type": "application/json",
  },
});

const useGetAll = () => {
  const [res, setRes] = useState({ status: "loading", msg: null, data: null });
  const [refetch, setRefetch] = useState(false);
  const [params, setParams] = useState(null);
  const [body, setBody] = useState(null);

  const fetch = (id) => {
    setParams({ id });
    setRefetch(true);
  };

  useEffect(() => {
    if (params !== null && refetch === true) {
      setRes({ status: "loading", msg: null, data: null });
      client
        .get(`/all/${params.id}`, {
          headers: { authorization: userAuth.getToken() },
        })
        .then((res) => {
          setRes({ status: "load", msg: null, data: res.data });
        })
        .catch((err) => {
          setRes({
            status: "error",
            msg: err.response.data.message,
            data: null,
          });
        })
        .finally(() => {
          setRefetch(false);
        });
    }
  }, [refetch]);

  return { res, fetch };
};

const useGet = () => {
  const [res, setRes] = useState({ status: "loading", msg: null, data: null });
  const [refetch, setRefetch] = useState(false);
  const [params, setParams] = useState(null);
  const [body, setBody] = useState(null);

  const fetch = (id) => {
    setParams({ id });
    setRefetch(true);
  };

  useEffect(() => {
    if (params !== null && refetch === true) {
      setRes({ status: "loading", msg: null, data: null });
      client
        .get(`/${params.id}`, {
          headers: { authorization: userAuth.getToken() },
        })
        .then((res) => {
          setRes({ status: "load", msg: null, data: res.data });
        })
        .catch((err) => {
          setRes({
            status: "error",
            msg: err.response.data.message,
            data: null,
          });
        })
        .finally(() => {
          setRefetch(false);
        });
    }
  }, [refetch]);

  return { res, fetch };
};

const useAdd = () => {
  const [res, setRes] = useState({ status: "loading", msg: null, data: null });
  const [refetch, setRefetch] = useState(false);
  const [params, setParams] = useState(null);
  const [body, setBody] = useState(null);

  const fetch = (data) => {
    setBody(data);
    setRefetch(true);
  };

  useEffect(() => {
    if (body !== null && refetch === true) {
      setRes({ status: "loading", msg: null, data: null });
      client
        .post(`/`, body, { headers: { authorization: userAuth.getToken() } })
        .then((res) => {
          setRes({ status: "load", msg: null, data: res.data });
        })
        .catch((err) => {
          console.log(err);
          setRes({
            status: "error",
            msg: err.response.data.message,
            data: null,
          });
        })
        .finally(() => {
          setRefetch(false);
        });
    }
  }, [refetch]);

  return { res, fetch };
};

const useUpdate = () => {
  const [res, setRes] = useState({ status: "loading", msg: null, data: null });
  const [refetch, setRefetch] = useState(false);
  const [params, setParams] = useState(null);
  const [body, setBody] = useState(null);

  const fetch = (id, data) => {
    setParams({ id });
    setBody(data);
    setRefetch(true);
  };

  useEffect(() => {
    if (body !== null && params !== null && refetch === true) {
      setRes({ status: "loading", msg: null, data: null });
      client
        .put(`/${params.id}`, body, {
          headers: { authorization: userAuth.getToken() },
        })
        .then((res) => {
          setRes({ status: "load", msg: null, data: res.data });
        })
        .catch((err) => {
          setRes({
            status: "error",
            msg: err.response.data.message,
            data: null,
          });
        })
        .finally(() => {
          setRefetch(false);
        });
    }
  }, [refetch, params, body]);

  return { res, fetch };
};

const useRemove = () => {
  const [res, setRes] = useState({ status: "loading", msg: null, data: null });
  const [refetch, setRefetch] = useState(false);
  const [params, setParams] = useState(null);
  const [body, setBody] = useState(null);

  const fetch = (id) => {
    setParams({ id });
    setRefetch(true);
  };

  useEffect(() => {
    if (params !== null && refetch === true) {
      setRes({ status: "loading", msg: null, data: null });
      client
        .delete(`/${params.id}`, {
          headers: { authorization: userAuth.getToken() },
        })
        .then((res) => {
          setRes({ status: "load", msg: null, data: res.data });
        })
        .catch((err) => {
          setRes({
            status: "error",
            msg: err.response.data.message,
            data: null,
          });
        })
        .finally(() => {
          setRefetch(false);
        });
    }
  }, [refetch]);

  return { res, fetch };
};

export default { useGetAll, useGet, useAdd, useUpdate, useRemove };

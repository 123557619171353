import { useState, useEffect } from "react";
import axios from "axios";

const client = axios.create({
  baseURL: `${process.env.REACT_APP_URL_SERVER}/auth`,
  timeout: 31000,
  headers: {
    "Content-Type": "application/json"
  },
});

const useLogin = () => {
  const [res, setRes] = useState({ status: "loading", msg: null, data: null });
  const [refetch, setRefetch] = useState(false);
  const [params, setParams] = useState(null);
  const [body, setBody] = useState(null);

  const fetch = (email, password) => {
    setBody({email, password})
    setRefetch(true);
  };

  useEffect(() => {
    if (body !== null && refetch === true) {
      setRes({ status: "loading", msg: null, data: null });
      client
        .post(`/login`, body)
        .then((res) => {
          setRes({ status: "load", msg: null, data: res.data });
        })
        .catch((err) => {
          setRes({ status: "error", msg: err, data: null });
        })
        .finally(() => {
          setRefetch(false);
        });
    }
  }, [refetch]);

  return { res, fetch };
};

const useRegister = () => {
  const [res, setRes] = useState({ status: "loading", msg: null, data: null });
  const [refetch, setRefetch] = useState(false);
  const [params, setParams] = useState(null);
  const [body, setBody] = useState(null);

  const fetch = (data) => {
    setBody(data);
    setRefetch(true);
  };

  useEffect(() => {
    if (body !== null && refetch === true) {
      setRes({ status: "loading", msg: null, data: null });
      client
        .post(`/register`, body)
        .then((res) => {
          setRes({ status: "load", msg: null, data: res.data });
        })
        .catch((err) => {
          setRes({ status: "error", msg: err, data: null });
        })
        .finally(() => {
          setRefetch(false);
        });
    }
  }, [refetch]);

  return { res, fetch };
};



export default {useLogin, useRegister}
import { useState, useEffect } from "react";
import axios from "axios";
import userAuth from "../hooks/userAuth";

const client = axios.create({
  baseURL: `${process.env.REACT_APP_URL_SERVER}/roles`,
  timeout: 31000,
  headers: {
    "Content-Type": "application/json"
  },
});

const useGetAll = () => {
  const [res, setRes] = useState({ status: "loading", msg: null, data: null });
  const [refetch, setRefetch] = useState(false);
  const [params, setParams] = useState(null);
  const [body, setBody] = useState(null);

  const fetch = () => {
    setRefetch(true);
  };

  useEffect(() => {
    if (refetch === true) {
      setRes({ status: "loading", msg: null, data: null });
      client
        .get(`/`, {headers: {"authorization": userAuth.getToken()}})
        .then((res) => {
          setRes({ status: "load", msg: null, data: res.data });
        })
        .catch((err) => {
          setRes({ status: "error", msg: err, data: null });
        })
        .finally(() => {
          setRefetch(false);
        });
    }
  }, [refetch]);

  return { res, fetch };
};

const useGet = () => {
  const [res, setRes] = useState({ status: "loading", msg: null, data: null });
  const [refetch, setRefetch] = useState(false);
  const [params, setParams] = useState(null);
  const [body, setBody] = useState(null);

  const fetch = (id) => {
    setParams({ id });
    setRefetch(true);
  };

  useEffect(() => {
    if (params !== null && refetch === true) {
      setRes({ status: "loading", msg: null, data: null });
      client
        .get(`/${params.id}`, {headers: {"authorization": userAuth.getToken()}})
        .then((res) => {
          setRes({ status: "load", msg: null, data: res.data });
        })
        .catch((err) => {
          setRes({ status: "error", msg: err, data: null });
        })
        .finally(() => {
          setRefetch(false);
        });
    }
  }, [refetch]);

  return { res, fetch };
};


export default {useGetAll, useGet}
import "./navbar.scss";
import logo from "../../assets/img/logo.png";
import { useRef, useState, useEffect } from "react";
import MenuUser from "../UserMenu/menuUser";

import { FaUserCircle } from "react-icons/fa";

const Navbar = ({ showDropdown, setShowDropdown, setEditPassword, editUser, user }) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const editPassword = () => {
    setEditPassword(user.id)
  }

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <div className="navbar">
      <div className="navbar-title-link">
        <a href={"http://integralabs.ai"}>
          <img src={logo} alt={"Integra Labs"} />
        </a>
      </div>

      {user ? (
        <div ref={wrapperRef}>
          <div>
            <div className="navbar-user">
              <div
                className="navbar-user-title"
                onClick={() => toggleDropdown()}
              >
                {user.name} {user.lastname}
              </div>
              <div className="navbar-user-icon">
                <FaUserCircle />
              </div>
            </div>
          </div>

          <MenuUser showUserMenu={showDropdown} editPassword={editPassword} editUser={editUser} />
        </div>
      ) : null}
    </div>
  );
};

export default Navbar;

import "./menuUser.scss";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

import userAuth from "../../hooks/userAuth";

const MenuUser = ({ showUserMenu, editUser, editPassword }) => {
  const navigate = useNavigate();

  const logOut = () => {
    userAuth.logOut();
    navigate("/login");
  }

  return (
    <Fragment>
      {showUserMenu ? (
        <div className="menu-user-content">
          <div className="menu-user-item" onClick={() => editUser()}>
            Editar
          </div>
          <div className="menu-user-item" onClick={() => editPassword()}>
            Editar Contraseña
          </div>
          <div className="menu-user-item" onClick={() => logOut()}>
            Cerrar Sesion
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

export default MenuUser;
